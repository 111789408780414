import {
  ArrowDropDown,
  ArrowRight,
  Info,
  InfoOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import exportDiscoveryTree from 'redux/actions/Common/exportDiscoveryTree';

// Component to render individual questions recursively
const QuestionNode = ({
  question,
  type,
  expandAll,
  collapseAll,
  dealTree,
  interactionAnswers,
}) => {
  const { id, qno, q, yqs, nqs, y, n, qw, us, wus, sa, rsa } = question;

  const [collapsed, setCollapsed] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  // console.log('dealTree', dealTree);

  useEffect(() => {
    if (expandAll) {
      setCollapsed((prev) => {
        return {
          ...prev,
          [id]: false,
        };
      });
    }
    if (collapseAll) {
      setCollapsed((prev) => {
        return {
          ...prev,
          [id]: true,
        };
      });
    }
  }, [expandAll, collapseAll]);

  console.log(interactionAnswers);

  return (
    <Box
      sx={{
        marginLeft: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        {(yqs && yqs.length > 0) || (nqs && nqs.length > 0) ? (
          <>
            {!collapsed?.[id] ? (
              <ArrowDropDown
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCollapsed((prev) => {
                    return {
                      ...prev,
                      [id]: true,
                    };
                  });
                }}
              />
            ) : (
              <ArrowRight
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCollapsed((prev) => {
                    return {
                      ...prev,
                      [id]: false,
                    };
                  });
                }}
              />
            )}
          </>
        ) : (
          <ArrowDropDown
            sx={{
              cursor: 'pointer',
              visibility: 'hidden',
            }}
          />
        )}
        <Typography
          sx={{
            color: type === 'n' ? 'red' : 'black',
            fontWeight: Object.keys(interactionAnswers || {}).includes(id)
              ? 'bold'
              : '',
          }}
        >
          <strong>{qno}.</strong> {q}
        </Typography>
        {dealTree ? (
          <Typography
            sx={{
              color: sa === 'n' ? 'red' : 'black',
            }}
          >
            <strong>{sa === 'n' ? 'No' : sa === 'y' ? 'Yes' : ''}</strong>
          </Typography>
        ) : null}
        <IconButton
          size="small"
          onMouseEnter={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          onMouseLeave={() => {
            setAnchorEl(null);
          }}
        >
          <InfoOutlined />
        </IconButton>
        <Tooltip arrow title={'Question Weight'} placement="top">
          <Typography
            fontWeight={'bold'}
            sx={{
              // border: '1px solid #d3d3d3',
              // borderRadius: '50%',
              // width: 20,
              // height: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // p: 2,
            }}
          >
            {qw}
          </Typography>
        </Tooltip>
        {us ? (
          <>
            <Typography>, </Typography>
            <Tooltip arrow title={'Usable Score'} placement="top">
              <Typography
                fontWeight={'bold'}
                sx={{
                  // border: '1px solid #d3d3d3',
                  // borderRadius: '50%',
                  // width: 20,
                  // height: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {us?.toFixed(2)}
              </Typography>
            </Tooltip>
          </>
        ) : null}
        {wus ? (
          <>
            <Typography>, </Typography>
            <Tooltip arrow title={'Weighted Usable Score'} placement="top">
              <Typography
                fontWeight={'bold'}
                sx={{
                  // border: '1px solid #d3d3d3',
                  // borderRadius: '50%',
                  // width: 20,
                  // height: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {wus?.toFixed(2)}
              </Typography>
            </Tooltip>
          </>
        ) : null}
        <Popper
          open={openMenu}
          anchorEl={anchorEl}
          sx={{
            zIndex: 1300,
          }}
          placement={'bottom'}
        >
          <Box
            sx={{
              display: 'flex',
              border: '1px solid #d3d3d3',
              borderRadius: 1,
              padding: 2,
              background: '#fff',
              boxShadow: 1,
              gap: 2,
            }}
          >
            <Typography fontWeight={'bold'} sx={{}}>
              Y: {y}
            </Typography>
            <Typography fontWeight={'bold'} sx={{}}>
              N: {n}
            </Typography>
          </Box>
        </Popper>
      </Box>
      {/* Render yqs before nqs */}
      {yqs && yqs.length > 0 && !collapsed?.[id] && (
        <Box>
          {yqs.map((yq) => (
            <QuestionNode
              key={yq.id}
              question={yq}
              type={'y'}
              expandAll={expandAll}
              collapseAll={collapseAll}
              dealTree={dealTree}
              interactionAnswers={interactionAnswers}
            />
          ))}
        </Box>
      )}
      {nqs && nqs.length > 0 && !collapsed?.[id] && (
        <Box>
          {nqs.map((nq) => (
            <QuestionNode
              key={nq.id}
              question={nq}
              type={'n'}
              expandAll={expandAll}
              collapseAll={collapseAll}
              dealTree={dealTree}
              interactionAnswers={interactionAnswers}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

// Component to render topics, subtopics, and their questions
const TopicNode = ({
  topicData,
  collapseAll,
  expandAll,
  dealTree,
  interactionAnswers,
}) => {
  const { id, topic, objective, score, questions, subtopic } = topicData;
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [expanded, setExapnded] = useState({});

  useEffect(() => {
    if (expandAll) {
      setExapnded((prev) => {
        return {
          ...prev,
          [id]: true,
        };
      });
    }
    if (collapseAll) {
      setExapnded((prev) => {
        return {
          ...prev,
          [id]: false,
        };
      });
    }
  }, [expandAll, collapseAll]);

  // console.log('interactionAnswers', interactionAnswers);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {expanded?.[id] ? (
          <ArrowDropDown
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setExapnded((prev) => {
                return {
                  ...prev,
                  [id]: false,
                };
              });
            }}
          />
        ) : (
          <ArrowRight
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setExapnded((prev) => {
                return {
                  ...prev,
                  [id]: true,
                };
              });
            }}
          />
        )}
        <Typography>{topic}</Typography>
        <IconButton
          size="small"
          onMouseEnter={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          onMouseLeave={() => {
            setAnchorEl(null);
          }}
        >
          <InfoOutlined />
        </IconButton>
        <Tooltip arrow title={'Score Weightage'} placement="auto">
          <Typography
            fontWeight={'bold'}
            sx={{
              border: '1px solid #d3d3d3',
              borderRadius: '50%',
              width: 20,
              height: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            {score}
          </Typography>
        </Tooltip>
        <Popper
          open={openMenu}
          anchorEl={anchorEl}
          sx={{
            zIndex: 1300,
          }}
          placement={'bottom'}
        >
          <Box
            sx={{
              display: 'flex',
              border: '1px solid #d3d3d3',
              borderRadius: 1,
              padding: 2,
              background: '#fff',
              minWidth: 300,
              maxWidth: 300,
              boxShadow: 1,
            }}
          >
            <Typography>{objective}</Typography>
          </Box>
        </Popper>
      </Box>
      {expanded?.[id] ? (
        <>
          {subtopic && subtopic.length > 0 && (
            <Box
              sx={{
                marginLeft: 2,
              }}
            >
              {subtopic.map((sub) => (
                <TopicNode
                  key={sub.id}
                  topicData={sub}
                  expandAll={expandAll}
                  collapseAll={collapseAll}
                  dealTree={dealTree}
                  interactionAnswers={interactionAnswers}
                />
              ))}
            </Box>
          )}
          {questions && questions.length > 0 && (
            <>
              {questions.map((question) => (
                <QuestionNode
                  key={question.id}
                  question={question}
                  expandAll={expandAll}
                  collapseAll={collapseAll}
                  dealTree={dealTree}
                  interactionAnswers={interactionAnswers}
                />
              ))}
            </>
          )}
        </>
      ) : null}
    </Box>
  );
};

// Root component to render an array of topics
const RenderTopicsArray = ({
  topics,
  dealTree,
  showExportButton,
  treeId,
  dealId,
  interactionAnswers,
}) => {
  const [expandAll, setExpandAll] = useState(false);
  const [collapseAll, setCollapseAll] = useState(false);

  const [exportingTree, setExportingTree] = useState(false);

  const dispatch = useDispatch();

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={2}
        justifyContent={'flex-end'}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        {showExportButton ? (
          <Button
            color="primary"
            variant="contained"
            startIcon={exportingTree ? <CircularProgress size={20} /> : null}
            disabled={exportingTree}
            onClick={() => {
              setExportingTree(true);
              dispatch(
                exportDiscoveryTree(treeId, true, dealId, (data) => {
                  setExportingTree(false);
                  console.log(data);
                  window.open(data, '_blank');
                }),
              );
            }}
            sx={{
              padding: '4px 8px',
              // marginRight: 2,
            }}
          >
            Export Tree
          </Button>
        ) : null}
        <Button
          color="primary"
          variant="outlined"
          size="small"
          sx={{ padding: '4px 8px' }}
          onClick={() => {
            setExpandAll(true);
            setCollapseAll(false);
          }}
        >
          Expand All
        </Button>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          sx={{ padding: '4px 8px' }}
          onClick={() => {
            setExpandAll(false);
            setCollapseAll(true);
          }}
        >
          Collapse All
        </Button>
      </Box>
      {topics.map((topicData) => (
        <TopicNode
          key={topicData.id}
          topicData={topicData}
          collapseAll={collapseAll}
          expandAll={expandAll}
          dealTree={dealTree}
          interactionAnswers={interactionAnswers}
        />
      ))}
    </>
  );
};

const QuestionFrameworkTree = ({
  data,
  dealTree,
  showExportButton,
  treeId,
  dealId,
  interactionAnswers,
}) => {
  return (
    <RenderTopicsArray
      topics={data}
      dealTree={dealTree}
      showExportButton={showExportButton}
      treeId={treeId}
      dealId={dealId}
      interactionAnswers={interactionAnswers}
    />
  );
};

export default QuestionFrameworkTree;
