import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch } from 'react-redux';
import getAllFineTuneModelProjects from 'redux/actions/Common/getAllFineTuneModelProjects';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  Add,
  ArrowBack,
  Assignment,
  ChevronRight,
  Delete,
  Edit,
  WorkHistory,
} from '@mui/icons-material';
import createFineTuningProject from 'redux/actions/Common/createFineTuningProject';
import getAllFineTuningJobs from 'redux/actions/Common/getAllFineTuningJobs';
import createFineTuningJob from 'redux/actions/Common/createFineTuningJob';
import updateFineTuningProject from 'redux/actions/Common/updateFineTuningProject';
import deleteFineTuningProject from 'redux/actions/Common/deleteFineTuningProject';
import processSheet from 'redux/actions/Common/processSheet';

const FineTuneModels = () => {
  const theme = useTheme();
  const history = useHistory();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [processing, setProcessing] = useState(false);
  const [fineTuningProjects, setFineTuningProjects] = useState([]);
  const [dataPoints, setDataPoints] = useState([]);
  const [modelName, setModelName] = useState('gpt-4o-mini-2024-07-18');
  const [prompt, setPrompt] = useState('');
  const [title, setTitle] = useState('');
  const dispatch = useDispatch();

  const [createMode, setCreateMode] = useState(false);

  const [showPromptPreview, setShowPromptPreview] = useState('');
  const [showPromptPreviewFor, setShowPromptPreviewFor] = useState('');

  const [currentTab, setCurrentTab] = useState('projects');
  const [showCreateFineTuningJob, setShowCreateFineTuningJob] = useState(null);
  const [fineTuningJobs, setFineTuningJobs] = useState([]);

  const [newModelName, setNewModelName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [sheetUrl, setSheetUrl] = useState('');
  const [showSheetURLField, setShowSheetURLField] = useState(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

  const [processingSheet, setProcessingSheet] = useState(false);

  const handleAddDataPointClick = (event) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (currentTab === 'projects') {
      dispatch(
        getAllFineTuneModelProjects((data) => setFineTuningProjects(data)),
      );
    } else if (currentTab === 'jobs') {
      dispatch(
        getAllFineTuningJobs(null, '', (data) => setFineTuningJobs(data)),
      );
    }
  }, [currentTab]);

  useEffect(() => {
    if (typeof createMode === 'object') {
      setModelName(createMode?.model || '');
      setPrompt(createMode?.prompt || '');
      setTitle(createMode?.title || '');
      setDataPoints(createMode?.customFields?.dataPoints || []);
    } else if (!createMode) {
      setModelName('gpt-4o-mini-2024-07-18');
      setPrompt('');
      setTitle('');
      setDataPoints([]);
    }
  }, [createMode]);

  useEffect(() => {}, []);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{ xs: '100vh', sm: '100vh', md: 'calc(100vh - 80px)' }}
        sx={{
          overflow: 'scroll',
          // padding: '20px',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
      >
        <Tabs
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(newValue)}
          aria-label="Tabs"
          orientation={'horizontal'}
          // indicatorColor={theme.palette.background.default}
          variant="scrollable"
          // scrollButtons={!isMd}
          allowScrollButtonsMobile
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
        >
          <Tab
            value={'process_sheet'}
            label={<Typography variant="body1">Process Sheets</Typography>}
            id={`simple-tab-${0}`}
            aria-controls={`simple-tabpanel-${0}`}
            sx={{}}
          />
          <Tab
            value={'projects'}
            label={<Typography variant="body1">Projects</Typography>}
            id={`simple-tab-${1}`}
            aria-controls={`simple-tabpanel-${1}`}
            sx={{}}
          />
          <Tab
            value={'jobs'}
            label={<Typography variant="body1">Fine Tuning Jobs</Typography>}
            id={`simple-tab-${2}`}
            aria-controls={`simple-tabpanel-${2}`}
            sx={{}}
          />
        </Tabs>
        {currentTab === 'process_sheet' ? (
          <Box
            sx={{
              padding: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <TextField
              // autoFocus
              placeholder="Google Sheet URL"
              variant="outlined"
              color="primary"
              size="small"
              name="sheetUrl"
              value={sheetUrl}
              onChange={(e) => {
                setSheetUrl(e.target.value);
              }}
              fullWidth
            />
            <Button
              color="primary"
              variant="contained"
              startIcon={
                processingSheet ? <CircularProgress size={20} /> : null
              }
              disabled={!sheetUrl?.trim() || processingSheet}
              onClick={() => {
                setProcessingSheet(true);
                dispatch(
                  processSheet(
                    sheetUrl,
                    () => {
                      setProcessingSheet(false);
                      setSheetUrl('');
                    },
                    () => {
                      setProcessingSheet(false);
                      setSheetUrl('');
                    },
                  ),
                );
              }}
            >
              Submit
            </Button>
          </Box>
        ) : currentTab === 'projects' ? (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: createMode ? 'flex-start' : 'flex-end',
                p: 2,
              }}
            >
              {createMode ? (
                <Button
                  startIcon={<ArrowBack />}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => setCreateMode(false)}
                >
                  Go Back
                </Button>
              ) : (
                <Button
                  startIcon={<Add />}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => setCreateMode(true)}
                >
                  Create New Project
                </Button>
              )}
            </Box>
            {createMode ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    alignItems: 'start',
                    p: 4,
                  }}
                >
                  <FormControl variant="outlined" size="small">
                    <InputLabel id="model-select-label">
                      Select Model
                    </InputLabel>
                    <Select
                      labelId="model-select-label"
                      label="Select Model"
                      value={modelName || ''}
                      onChange={(e) => {
                        setModelName(e.target.value);
                      }}
                    >
                      <MenuItem value={'gpt-4o-2024-08-06'}>
                        gpt-4o-2024-08-06
                      </MenuItem>
                      <MenuItem value={'gpt-4o-mini-2024-07-18'}>
                        gpt-4o-mini-2024-07-18
                      </MenuItem>
                      <MenuItem value={'gpt-4-0613'}>gpt-4-0613</MenuItem>
                      <MenuItem value={'gpt-3.5-turbo-0125'}>
                        gpt-3.5-turbo-0125
                      </MenuItem>
                      <MenuItem value={'gpt-3.5-turbo-1106'}>
                        gpt-3.5-turbo-1106
                      </MenuItem>
                      <MenuItem value={'gpt-3.5-turbo-0613'}>
                        gpt-3.5-turbo-0613
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    // autoFocus
                    placeholder="Project Title"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    fullWidth
                  />
                  <TextField
                    // autoFocus
                    placeholder="System Prompt"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="prompt"
                    multiline
                    rows={6}
                    value={prompt}
                    onChange={(e) => {
                      setPrompt(e.target.value);
                    }}
                    fullWidth
                  />
                  {dataPoints?.map((datapoint, di) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        border: '1px solid #d3d3d3',
                        p: 2,
                        position: 'relative',
                        width: 1,
                        alignItems: 'stretch',
                      }}
                    >
                      <Typography
                        sx={{
                          position: 'absolute',
                          top: -10,
                          left: 5,
                          background: '#fff',
                        }}
                      >
                        Data Point {di + 1}.
                      </Typography>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                          position: 'absolute',
                          top: 5,
                          right: 16,
                          padding: '4px 8px',
                        }}
                        onClick={() => {
                          setDataPoints((prev) =>
                            prev.filter((dp, dpi) => dpi !== di),
                          );
                        }}
                      >
                        Remove
                      </Button>
                      {datapoint?.messages?.map((message, mi) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                          }}
                        >
                          <Typography sx={{ color: 'text.secondary' }}>
                            {message?.role === 'user'
                              ? 'Question'
                              : message?.role === 'assistant'
                              ? 'Answer'
                              : ''}
                          </Typography>
                          <TextField
                            // autoFocus
                            placeholder={
                              message?.role === 'user'
                                ? 'Question'
                                : message?.role === 'assistant'
                                ? 'Answer'
                                : ''
                            }
                            variant="outlined"
                            color="primary"
                            size="small"
                            name={
                              message?.role === 'user'
                                ? 'question'
                                : message?.role === 'assistant'
                                ? 'answer'
                                : ''
                            }
                            multiline={message?.role === 'assistant'}
                            rows={message?.role === 'assistant' ? 6 : 1}
                            value={message?.content}
                            onChange={(e) => {
                              setDataPoints((prev) =>
                                prev?.map((dp, dpi) => {
                                  if (dpi === di) {
                                    return {
                                      messages: dp?.messages?.map(
                                        (msg, msgi) => {
                                          if (msgi === mi) {
                                            return {
                                              ...msg,
                                              content: e.target.value,
                                            };
                                          } else return msg;
                                        },
                                      ),
                                    };
                                  } else return dp;
                                }),
                              );
                            }}
                            fullWidth
                          />
                        </Box>
                      ))}
                      <Button
                        startIcon={<Add />}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          setDataPoints((prev) =>
                            prev?.map((dp, dpi) => {
                              if (dpi === di) {
                                return {
                                  messages: [
                                    ...dp.messages,
                                    {
                                      content: '',
                                      role: 'user',
                                    },
                                    {
                                      content: '',
                                      role: 'assistant',
                                    },
                                  ],
                                };
                              } else return dp;
                            }),
                          )
                        }
                      >
                        Add new question answer
                      </Button>
                    </Box>
                  ))}
                  {showSheetURLField ? (
                    <TextField
                      // autoFocus
                      placeholder="Google Sheet URL"
                      variant="outlined"
                      color="primary"
                      size="small"
                      name="sheetUrl"
                      value={sheetUrl}
                      onChange={(e) => {
                        setSheetUrl(e.target.value);
                      }}
                      fullWidth
                    />
                  ) : null}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flex: 1,
                      width: 1,
                    }}
                  >
                    <Button
                      startIcon={<Add />}
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        handleAddDataPointClick(e);
                      }}
                    >
                      Add new data point
                    </Button>
                    <Menu
                      id="add-section-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      disableScrollLock={true}
                      transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top',
                      }}
                      anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'bottom',
                      }}
                      style={{
                        marginTop: '15px',
                      }}
                    >
                      <Tooltip
                        title={
                          showSheetURLField
                            ? 'You can only provide one sheet url'
                            : ''
                        }
                        arrow
                        placement="right"
                      >
                        <MenuItem
                          value={'google_sheet_url'}
                          onClick={(e) => {
                            if (showSheetURLField) {
                              setShowSheetURLField(false);
                            } else {
                              setShowSheetURLField(true);
                            }
                            handleClose();
                          }}
                        >
                          <Typography
                            color={
                              showSheetURLField
                                ? 'text.secondary'
                                : 'text.primary'
                            }
                          >
                            Google Sheet URL
                          </Typography>
                        </MenuItem>
                      </Tooltip>
                      <MenuItem
                        value={'google_sheet_url'}
                        onClick={(e) => {
                          setDataPoints((prev) => {
                            return [
                              ...prev,
                              {
                                messages: [
                                  {
                                    content: '',
                                    role: 'user',
                                  },
                                  {
                                    content: '',
                                    role: 'assistant',
                                  },
                                ],
                              },
                            ];
                          });
                          handleClose();
                        }}
                      >
                        <Typography>Manual Data Points</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flex: 1,
                    width: 1,
                    borderTop: '1px solid #d3d3d3',
                    p: 2,
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (typeof createMode === 'object') {
                        dispatch(
                          updateFineTuningProject(
                            createMode?.id,
                            {
                              dataPointRequestDto: dataPoints,
                              model: modelName,
                              prompt: prompt,
                              title: title,
                              sheetUrl: sheetUrl,
                            },
                            () => {
                              setCreateMode(false);
                              setSheetUrl('');
                              dispatch(
                                getAllFineTuneModelProjects((data) =>
                                  setFineTuningProjects(data),
                                ),
                              );
                            },
                          ),
                        );
                      } else {
                        dispatch(
                          createFineTuningProject(
                            {
                              dataPointRequestDto: dataPoints,
                              model: modelName,
                              prompt: prompt,
                              title: title,
                              sheetUrl: sheetUrl,
                            },
                            () => {
                              setSheetUrl('');
                              setCreateMode(false);
                              dispatch(
                                getAllFineTuneModelProjects((data) =>
                                  setFineTuningProjects(data),
                                ),
                              );
                            },
                          ),
                        );
                      }
                    }}
                  >
                    {typeof createMode === 'object' ? 'Update' : 'Create'}
                  </Button>
                </Box>
              </Box>
            ) : fineTuningProjects && fineTuningProjects.length > 0 ? (
              <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell align="center">Model</TableCell>
                      <TableCell align="center">Created On</TableCell>
                      <TableCell align="center">Prompt</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fineTuningProjects.map((project) => (
                      <TableRow
                        key={project?.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {project?.title}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {project?.model}
                        </TableCell>
                        <TableCell align="center">
                          {moment(project?.createdOn).format('DD MMMM YYYY')}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {`${project?.prompt}`}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: 1,
                            }}
                          >
                            <Tooltip
                              title={'Preview Prompt'}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() => {
                                  setShowPromptPreviewFor(project?.title);
                                  setShowPromptPreview(project?.prompt);
                                }}
                              >
                                <Assignment color="default" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={'Create Fine Tuning Job'}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() => {
                                  setShowCreateFineTuningJob(project);
                                }}
                              >
                                <WorkHistory color="default" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={'Edit Project'}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() => {
                                  setCreateMode(project);
                                }}
                              >
                                <Edit
                                  color={
                                    prompt.transcript ? 'primary' : 'default'
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={'Delete Project'}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() => {
                                  setShowConfirmDeleteDialog(project?.id);
                                }}
                              >
                                <Delete color={'default'} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </>
        ) : currentTab === 'jobs' ? (
          <Box>
            {fineTuningJobs && fineTuningJobs.length > 0 ? (
              <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Model</TableCell>
                      <TableCell align="center">Base Model</TableCell>
                      <TableCell align="center">Created On</TableCell>
                      <TableCell align="center">Status</TableCell>
                      {/* <TableCell align="center">Actions</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fineTuningJobs.map((job) => (
                      <TableRow
                        key={job?.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {job?.modelName}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {job?.gptModelFineTuningJob?.model}
                        </TableCell>
                        <TableCell align="center">
                          {moment(job?.createdOn).format('DD MMMM YYYY')}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {`${job?.gptModelFineTuningJob?.status}`}
                        </TableCell>
                        {/* <TableCell component="th" scope="row" align="center">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: 1,
                            }}
                          >
                            <Tooltip
                              title={'Preview Prompt'}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() => {
                                  setShowPromptPreviewFor(job?.title);
                                  setShowPromptPreview(job?.prompt);
                                }}
                              >
                                <Assignment color="default" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={'Create Fine Tuning Job'}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() => {
                                  setShowCreateFineTuningJob(job);
                                }}
                              >
                                <WorkHistory color="default" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={'Edit Project'}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() => {
                                  setCreateMode(job);
                                }}
                              >
                                <Edit
                                  color={
                                    prompt.transcript ? 'primary' : 'default'
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </Box>
        ) : null}
      </Box>
      <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showPromptPreview)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setShowPromptPreview('');
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>
              {showPromptPreviewFor}(Prompt Preview)
            </Typography>
          </Box>
          <Box
            // ref={drawerContentRef}
            sx={{
              minHeight: 'calc(100vh - 80px)',
              maxHeight: 'calc(100vh - 80px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              padding: 2,
            }}
          >
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>
              {showPromptPreview}
            </Typography>
          </Box>
        </Box>
      </Drawer>
      <Drawer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showCreateFineTuningJob)}
        anchor="right"
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '100vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setShowCreateFineTuningJob(null);
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>
              Create Fine Tuning Job ({showCreateFineTuningJob?.title})
            </Typography>
          </Box>
          <Box
            // ref={drawerContentRef}
            sx={{
              minHeight: 'calc(100vh - 120px)',
              maxHeight: 'calc(100vh - 120px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              padding: 2,
            }}
          >
            <TextField
              // autoFocus
              placeholder="New Model Name"
              variant="outlined"
              color="primary"
              size="small"
              name="newModelName"
              value={newModelName}
              onChange={(e) => {
                setNewModelName(e.target.value);
              }}
              fullWidth
            />
          </Box>
          <Box
            sx={{
              borderTop: '1px solid #d3d3d3',
              padding: '8px 16px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(
                  createFineTuningJob(
                    newModelName,
                    showCreateFineTuningJob?.id,
                    () => {
                      setCurrentTab('jobs');
                    },
                  ),
                );
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this project?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setShowConfirmDeleteDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={(e) => {
              dispatch(
                deleteFineTuningProject(showConfirmDeleteDialog, () => {
                  dispatch(
                    getAllFineTuneModelProjects((data) =>
                      setFineTuningProjects(data),
                    ),
                  );
                  setShowConfirmDeleteDialog(null);
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Fixed>
  );
};

export default FineTuneModels;
