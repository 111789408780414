import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getAllDiscoveryTree = (limit, lastTime, callback) => {
  return async (dispatch) => {
    if (lastTime) {
      dispatch({
        type: 'GETTING_ALL_DISCOVERY_TREE_WITH_PAGINATION',
      });
    } else {
      dispatch({ type: 'GETTING_ALL_DISCOVERY_TREE' });
    }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(`/ads/discovery-tree`, {
            params: {
              requestId: reqId,
              limit: limit,
              lastSeen: lastTime,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'x-api-version': 'v2',
            },
          });
          if (response.status === 200) {
            if (lastTime) {
              await dispatch({
                type: 'ADD_DISCOVERY_TREE_TO_ALL_DISCOVERY_TREE',
                payload: {
                  tree: response?.data,
                  lastTime: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            } else {
              await dispatch({
                type: 'GETTING_ALL_DISCOVERY_TREE_DONE',
                payload: {
                  tree: response?.data,
                  lastTime: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            }
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'GETTING_ALL_DISCOVERY_TREE_FAILED',
              payload: `Failed to fetch discovery tree`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'GETTING_ALL_DISCOVERY_TREE_FAILED',
            payload: `Failed to fetch discovery tree`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'GETTING_ALL_DISCOVERY_TREE_FAILED',
        payload: `Failed to fetch discovery tree`,
      });
    }
  };
};

export default getAllDiscoveryTree;
