import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const processRagQuery = (payload, callback, errCallback) => {
  return async (dispatch) => {
    dispatch({ type: 'PROCESSING_RAG_QUERIES' });
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      const token = await user.getIdToken();
      const reqId = uuidv4();
      try {
        const response = await axios.post('/ads/mfnt/rag-query', payload, {
          params: {
            'request-id': reqId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          dispatch({
            type: 'PROCESSING_RAG_QUERIES_DONE',
          });
          if (typeof callback === 'function')
            callback(response?.data?.responses);
        } else {
          dispatch({
            type: 'PROCESSING_RAG_QUERIES_FAILED',
            payload: response?.data,
          });
          if (typeof errCallback === 'function') errCallback();
        }
      } catch (e) {
        console.log(e);
        dispatch({
          type: 'PROCESSING_RAG_QUERIES_FAILED',
          payload: e?.message,
        });
        if (typeof errCallback === 'function') errCallback();
      }
      unsubscribe();
    });
  };
};

export default processRagQuery;
